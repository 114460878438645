

// import React from "react";
// import { HydraAdmin } from "@api-platform/admin";

// Replace with your own API entrypoint
// For instance if https://example.com/api/books is the path to the collection of book resources, then the entrypoint is https://example.com/api
// export default () => (
//    <HydraAdmin entrypoint="http://localhost:8000/api" />
//);


import React, {Component} from "react";
import {
  HydraAdmin,
  FieldGuesser,
  ListGuesser,
  ResourceGuesser,
    CreateGuesser,
    EditGuesser,
    InputGuesser
} from "@api-platform/admin";
import { ReferenceField, ReferenceArrayField, SingleFieldList, ChipField, TextField, TextInput, ReferenceInput, SelectArrayInput, ReferenceArrayInput, AutocompleteInput } from "react-admin";

// SERVICE START
const ServiceList = (props) => (
    <ListGuesser {...props}>
      <FieldGuesser source="name" />
        <FieldGuesser source="code" />
        <FieldGuesser source="codeIri" />
      {/* Use react-admin components directly when you want complex fields. */}
      <ReferenceField label="Category" source="category" reference="categories">
        <TextField source="name" />
      </ReferenceField>
    </ListGuesser>
);
const ServicesCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="code" />
        <InputGuesser source="codeIri" />
        <ReferenceInput
            source="category"
            reference="categories"
            label="Categories"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </CreateGuesser>
);
const ServicesEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="code" />
        <InputGuesser source="codeIri" />
        <ReferenceInput
            source="category"
            reference="categories"
            label="Categories"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </EditGuesser>
);
// SERVICE END

// CONFIG PARAM START
const ConfigParamList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="name" />
        <FieldGuesser source="type" />
        <FieldGuesser source="required" />
        <FieldGuesser source="typeConfig" />
        {/* Use react-admin components directly when you want complex fields. */}
        <ReferenceField label="Service" source="service" reference="services">
            <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField label="Services" reference="services" source="services">
            <SingleFieldList>
                <ChipField source="name" />
            </SingleFieldList>
        </ReferenceArrayField>
    </ListGuesser>
);
const ConfigParamsCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="type" />
        <InputGuesser source="required" />
        <InputGuesser source="typeConfig" />
        <ReferenceInput
            source="service"
            reference="services"
            label="Services"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput
            reference="services"
            source="services"
            label="Services"
        >
            <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
    </CreateGuesser>
);
const ConfigParamsEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="type" />
        <InputGuesser source="required" />
        <InputGuesser source="typeConfig" />
        <ReferenceInput
            source="service"
            reference="services"
            label="Services"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput
            reference="services"
            source="services"
            label="Services"
        >
            <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
    </EditGuesser>
);
// CONFIG PARAM END

// SERV OP COUNTRY START
const ServOpCountryList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="code" />
        <FieldGuesser source="name" />
        {/* Use react-admin components directly when you want complex fields. */}
        <ReferenceField label="Country" source="country" reference="countries">
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Operator" source="operator" reference="operators">
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Service" source="service" reference="services">
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Partner" source="partner" reference="partners">
            <TextField source="name" />
        </ReferenceField>

    </ListGuesser>
);
const ServOpCountriesCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="code" />
        <InputGuesser source="name" />
        <ReferenceInput
            source="country"
            reference="countries"
            label="Countries"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="operator"
            reference="operators"
            label="Operators"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="service"
            reference="services"
            label="Services"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="partner"
            reference="partners"
            label="Partners"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </CreateGuesser>
);
const ServOpCountriesEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="code" />
        <InputGuesser source="name" />
        <ReferenceInput
            source="country"
            reference="countries"
            label="Countries"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="operator"
            reference="operators"
            label="Operators"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="service"
            reference="services"
            label="Services"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="partner"
            reference="partners"
            label="Partners"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </EditGuesser>
);
// SERV OP COUNTRY END

// PART SERV OP COUNTRY START
const PartServOpCountryList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="url" />
        <FieldGuesser source="method" />
        {/*
        <FieldGuesser source="requestType" />
        <FieldGuesser source="responseType" />
        <FieldGuesser source="respFormSucc" />
        <FieldGuesser source="needCallback" />
        <FieldGuesser source="isCallback" />
        */}
        {/* Use react-admin components directly when you want complex fields. */}
        <ReferenceField label="ConfigCom" source="configCom" reference="config_coms">
            <TextField source="protocolMethod" />
        </ReferenceField>
        <ReferenceField label="ServOpCountry" source="servOpCountry" reference="serv_op_countries">
            <TextField source="code" />
        </ReferenceField>
        <ReferenceField label="Partner" source="partner" reference="partners">
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="PartnerIpn" source="partnerIpn" reference="partner_ipns">
            <TextField source="code" />
        </ReferenceField>
    </ListGuesser>
);
const PartServOpCountriesCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="url" />
        <InputGuesser source="method" />
        <InputGuesser source="requestType" />
        <InputGuesser source="responseType" />
        {/*<InputGuesser source="respFormSucc" />*/}
        <TextInput multiline source="respFormSucc" />
        <InputGuesser source="needCallback" />
        <InputGuesser source="isCallback" />
        <ReferenceInput
            source="configCom"
            reference="config_coms"
            label="ConfigCom"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="protocolMethod" />
        </ReferenceInput>
        <ReferenceInput
            source="servOpCountry"
            reference="serv_op_countries"
            label="ServOpCountry"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="code" />
        </ReferenceInput>
        <ReferenceInput
            source="partner"
            reference="partners"
            label="Partner"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="partnerIpn"
            reference="partner_ipns"
            label="Partner Ipn"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="code" />
        </ReferenceInput>
    </CreateGuesser>
);
const PartServOpCountriesEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="url" />
        <InputGuesser source="method" />
        <InputGuesser source="requestType" />
        <InputGuesser source="responseType" />
        {/*<InputGuesser source="respFormSucc" />*/}
        <TextInput multiline source="respFormSucc" />
        <InputGuesser source="needCallback" />
        <InputGuesser source="isCallback" />
        <ReferenceInput
            source="configCom"
            reference="config_coms"
            label="ConfigCom"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="protocolMethod" />
        </ReferenceInput>
        <ReferenceInput
            source="servOpCountry"
            reference="serv_op_countries"
            label="ServOpCountry"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="code" />
        </ReferenceInput>
        <ReferenceInput
            source="partner"
            reference="partners"
            label="Partner"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="partnerIpn"
            reference="partner_ipns"
            label="Partner Ipn"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="code" />
        </ReferenceInput>
    </EditGuesser>
);
// PART SERV OP COUNTRY END

// PARTNER IPN START
const PartnerIpnList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="url" />
        <FieldGuesser source="ipnPathCode" />
        <FieldGuesser source="method" />
        {/*
        <FieldGuesser source="requestType" />
        <FieldGuesser source="responseType" />
        <FieldGuesser source="ipnFormSucc" />
        */}
        {/* Use react-admin components directly when you want complex fields. */}
        <ReferenceField label="ConfigCom" source="configCom" reference="config_coms">
            <TextField source="protocolMethod" />
        </ReferenceField>
        <ReferenceField label="Partner" source="partner" reference="partners">
            <TextField source="name" />
        </ReferenceField>
    </ListGuesser>
);
const PartnerIpnsCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="url" />
        <InputGuesser source="ipnPathCode" />
        <InputGuesser source="method" />
        <InputGuesser source="requestType" />
        <InputGuesser source="responseType" />
        {/*<InputGuesser source="ipnFormSucc" />*/}
        <TextInput multiline source="ipnFormSucc" />
        <ReferenceInput
            source="configCom"
            reference="config_coms"
            label="ConfigCom"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="protocolMethod" />
        </ReferenceInput>
        <ReferenceInput
            source="partner"
            reference="partners"
            label="Partner"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </CreateGuesser>
);
const PartnerIpnsEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="url" />
        <InputGuesser source="ipnPathCode" />
        <InputGuesser source="method" />
        <InputGuesser source="requestType" />
        <InputGuesser source="responseType" />
        {/*<InputGuesser source="ipnFormSucc" />*/}
        <TextInput multiline source="ipnFormSucc" />
        <ReferenceInput
            source="configCom"
            reference="config_coms"
            label="ConfigCom"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="protocolMethod" />
        </ReferenceInput>
        <ReferenceInput
            source="partner"
            reference="partners"
            label="Partner"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </EditGuesser>
);
// PARTNER IPN END

// CONFIG PARAM START
const ConfigInOutList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="name" />
        <FieldGuesser source="type" />
        <FieldGuesser source="required" />
        <FieldGuesser source="typeConfig" />
        <FieldGuesser source="location" />
        {/*
        <FieldGuesser source="value" />
        <FieldGuesser source="formulas" />
        <FieldGuesser source="dateExpiration" />
        */}
        {/* Use react-admin components directly when you want complex fields. */}
        <ReferenceField label="Parent" source="configInOut" reference="config_in_outs">
            <TextField source="configId" />
        </ReferenceField>
        <ReferenceField label="ConfigParam" source="configParam" reference="config_params">
            <TextField source="configId" />
        </ReferenceField>
        <ReferenceField label="PartServOpCountry" source="partServOpCountry" reference="part_serv_op_countries">
            <TextField source="code" />
        </ReferenceField>
        <ReferenceArrayField label="PartServOpCountries" reference="part_serv_op_countries" source="partServOpCountries">
            <SingleFieldList>
                <ChipField source="code" />
            </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField label="Partner IPN" source="partnerIpn" reference="partner_ipns">
            <TextField source="code" />
        </ReferenceField>
    </ListGuesser>
);
const ConfigInOutsCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="type" />
        <InputGuesser source="required" />
        <InputGuesser source="typeConfig" />
        <InputGuesser source="location" />
        <InputGuesser source="value" />
        {/*<InputGuesser source="formulas" />*/}
        <TextInput multiline source="formulas" />
        <InputGuesser source="dateExpiration" />
        <ReferenceInput
            source="configInOut"
            reference="config_in_outs"
            label="Parent"
            filterToQuery={searchText => ({ title: searchText, itemsPerPage: 100 })}
        >
            <AutocompleteInput optionText="configId" />
        </ReferenceInput>
        <ReferenceInput
            source="configParam"
            reference="config_params"
            label="ConfigParam"
            filterToQuery={searchText => ({ title: searchText, itemsPerPage: 100 })}
        >
            <AutocompleteInput optionText="configId" />
        </ReferenceInput>
        {/*
        <ReferenceInput
            source="partServOpCountry"
            reference="part_serv_op_countries"
            label="PartServOpCountry"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="code" />
        </ReferenceInput>
        */}
        <ReferenceArrayInput
            reference="part_serv_op_countries"
            source="partServOpCountries"
            label="PartServOpCountries"
        >
            <SelectArrayInput optionText="code" />
        </ReferenceArrayInput>
        <ReferenceInput
            source="partnerIpn"
            reference="partner_ipns"
            label="Partner IPN"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="code" />
        </ReferenceInput>
    </CreateGuesser>
);
const ConfigInOutsEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="type" />
        <InputGuesser source="required" />
        <InputGuesser source="typeConfig" />
        <InputGuesser source="location" />
        <InputGuesser source="value" />
        {/*<InputGuesser source="formulas" />*/}
        <TextInput multiline source="formulas" />
        <InputGuesser source="dateExpiration" />
        <ReferenceInput
            source="configInOut"
            reference="config_in_outs"
            label="Parent"
            filterToQuery={searchText => ({ title: searchText, itemsPerPage: 100 })}
        >
            <AutocompleteInput optionText="configId" />
        </ReferenceInput>
        <ReferenceInput
            source="configParam"
            reference="config_params"
            label="ConfigParam"
            filterToQuery={searchText => ({ title: searchText, itemsPerPage: 100 })}
        >
            <AutocompleteInput optionText="configId" />
        </ReferenceInput>
        {/*
        <ReferenceInput
            source="partServOpCountry"
            reference="part_serv_op_countries"
            label="PartServOpCountry"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="code" />
        </ReferenceInput>
        */}
        <ReferenceArrayInput
            reference="part_serv_op_countries"
            source="partServOpCountries"
            label="PartServOpCountries"
        >
            <SelectArrayInput optionText="code" />
        </ReferenceArrayInput>
        <ReferenceInput
            source="partnerIpn"
            reference="partner_ipns"
            label="Partner IPN"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="code" />
        </ReferenceInput>
    </EditGuesser>
);
// CONFIG PARAM END


class App extends Component{
    render() {
        console.log(process.env.REACT_APP_SERVER_DOC_URL);
        return <HydraAdmin entrypoint={process.env.REACT_APP_SERVER_DOC_URL}>
            <ResourceGuesser name="categories" />
            <ResourceGuesser
                name="services"
                list={ServiceList}
                create={ServicesCreate}
                edit={ServicesEdit}

            />
            <ResourceGuesser
                name="config_params"
                list={ConfigParamList}
                create={ConfigParamsCreate}
                edit={ConfigParamsEdit}
            />
            <ResourceGuesser name="operators" />
            <ResourceGuesser name="countries" />
            <ResourceGuesser name="partners" />
            <ResourceGuesser
                name="serv_op_countries"
                list={ServOpCountryList}
                create={ServOpCountriesCreate}
                edit={ServOpCountriesEdit}
            />
            <ResourceGuesser
                name="part_serv_op_countries"
                list={PartServOpCountryList }
                create={PartServOpCountriesCreate}
                edit={PartServOpCountriesEdit}
            />
            <ResourceGuesser
                name="partner_ipns"
                list={PartnerIpnList}
                create={PartnerIpnsCreate}
                edit={PartnerIpnsEdit}
            />
            <ResourceGuesser name="config_coms" />
            <ResourceGuesser
                name="config_in_outs"
                list={ConfigInOutList}
                create={ConfigInOutsCreate}
                edit={ConfigInOutsEdit}
            />
            <ResourceGuesser name="transactions" />
            <ResourceGuesser name="transaction_datas" />
        </HydraAdmin>
    }
}

export default App;